<template>
  <div class="communities">
    <CommunitiesInteractionPopup
      :active="isCommunitiesInteractionPopupOpen"
      @close="closeCommunitiesInteraction"
    ></CommunitiesInteractionPopup>
    <div v-if="checkPermissions()($store.getters['auth/globalPermissionsValue'], 'admin')">
      <button class="primary-full" :class="{ spinner: isLoading }" @click="createDebugCommunity">
        Create community (debug)
      </button>
    </div>
    <ul class="v-step-all-communities">
      <li
        v-for="community in $store.state.selection.communities"
        :key="community.id"
        @dblclick="
          ;(!checkPermissions()(community.permissionsValue, 'accepted') &&
            !checkPermissions()(community.permissionsValue, 'owner')) ||
          !checkCommunityStatus(community.community.paymentData.status)
            ? ''
            : $emit('doubleClickSelect')
        "
      >
        <input
          name="community-selection"
          :id="'box-' + community.id"
          :disabled="
            (!checkPermissions()(community.permissionsValue, 'accepted') &&
              !checkPermissions()(community.permissionsValue, 'owner')) ||
              !checkCommunityStatus(community.community.paymentData.status)
          "
          type="radio"
          v-model="selectedCommunity"
          :value="community"
        />
        <label :for="'box-' + community.id">
          <div
            class="_box"
            :class="{
              active: selectedCommunity.id === community.id,
              disabled:
                (!checkPermissions()(community.permissionsValue, 'accepted') &&
                  !checkPermissions()(community.permissionsValue, 'owner')) ||
                !checkCommunityStatus(community.community.paymentData.status)
            }"
          >
            <div
              class="banner blue"
              v-show="
                !checkPermissions()(community.permissionsValue, 'accepted') &&
                  !checkPermissions()(community.permissionsValue, 'owner')
              "
            >
              <h2 class="primary">{{ $t('selection.communities.not-accepted') }}</h2>
            </div>
            <div class="banner blue" v-show="!checkCommunityStatus(community.community.paymentData.status)">
              <h2
                class="primary"
                v-if="
                  community.community.paymentData.status !== 'CANCELED' &&
                    checkPermissions()(community.permissionsValue, 'owner')
                "
              >
                {{ $t('selection.communities.not-paid') }}
              </h2>
              <h2
                class="primary"
                v-if="
                  community.community.paymentData.status === 'CANCELED' &&
                    checkPermissions()(community.permissionsValue, 'owner')
                "
              >
                {{ $t('selection.communities.canceled') }}
              </h2>
              <h2 class="primary" v-if="!checkPermissions()(community.permissionsValue, 'owner')">
                {{ $t('selection.communities.inactive') }}
              </h2>
            </div>
            <div class="name">
              <h3 class="primary">{{ community.community.name }}</h3>
            </div>
            <div class="image">
              <img :src="community.community.logo" alt="community-image" />
            </div>
            <div
              class="edit"
              v-if="
                checkPermissions()(community.permissionsValue, 'owner') ||
                  checkPermissions()(community.permissionsValue, 'admin')
              "
            >
              <a class="a-edit" @click="editCommunity(community.community.id)">
                <i class="fas fa-edit"></i>
              </a>
            </div>
            <!-- <div class="members-online"><p class="primary">{{ community.membersOnline }} Members Online</p></div> -->
          </div>
        </label>
      </li>
      <!-- NOTE: SPONSOR SECTION -->
      <!-- <li class="sponsor">
        <a href="#" target="_blank">
          <label>
            <div class="_box">
              <div class="sponsor-banner uk-badge">
                <p class="primary">{{ $t('selection.communities.sponsor') }}</p>
              </div>
              <div class="sponsor-image">
                <img
                  src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi5.walmartimages.com%2Fasr%2F285aa5bf-3e49-4a2d-88bf-3e6e7f87b399_1.361375f0d35a0136380a40e83141ea37.jpeg%3FodnHeight%3D450%26odnWidth%3D450%26odnBg%3Dffffff&f=1&nofb=1"
                  alt="sponsor-image"
                />
              </div>
              <div class="sponsor-name">
                <h3 class="primary">Spaghetti Inc</h3>
              </div>
            </div>
          </label>
        </a>
      </li> -->
      <li class="ad">
        <Adsense
          ins-style="display:inline-block;width:245px;height:280px"
          data-ad-client="ca-pub-7037140739891615"
          data-ad-slot="9386554443"
        >
        </Adsense>
      </li>
      <li class="add" @click="isCommunitiesInteractionPopupOpen = true">
        <div class="_box v-step-join-community">
          <div class="icon">
            <span uk-icon="icon: plus; ratio: 5;"></span>
          </div>
        </div>
      </li>
    </ul>

    <v-tour
      :name="this.$route.name"
      :steps="tourSteps"
      :callbacks="finishCallbackOnly"
      :options="translatedLabels"
    ></v-tour>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/selection/selection-boxes.less"></style>

<script>
import { mapGetters } from 'vuex'
import CommunitiesInteractionPopup from '@/components/CommunitiesInteractionPopup'
import TourManager from '@/mixins/TourManager'

export default {
  name: 'Communities',
  components: {
    CommunitiesInteractionPopup
  },
  mixins: [TourManager],
  data() {
    return {
      isLoading: false,
      isCommunitiesInteractionPopupOpen: false,
      tourSteps: [
        {
          target: '.v-step-all-communities',
          content: this.$t('tour.communities.all-communities'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-join-community',
          content: this.$t('tour.communities.join-community'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-continue-button',
          content: this.$t('tour.communities.continue-button'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-profile-button',
          content: this.$t('tour.communities.profile-button'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-language-picker',
          content: this.$t('tour.communities.language-picker'),
          params: {
            highlight: true
          }
        }
      ]
    }
  },
  apollo: {
    communities: {
      query: require('@/graphql/selection/myCommunities.gql'),
      update: data => data.me.communities,
      subscribeToMore: {
        document: require('@/graphql/selection/subscribeToMyCommunities.gql'),
        variables() {
          return {
            userId: this.$store.state.auth.user ? this.$store.state.auth.user.id : ''
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          this.fetchAndUpdateCommunitites()
        }
      }
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions']),
    editCommunity(communityId) {
      const community = this.$store.state.selection.communities.filter(
        community => community.community.id === communityId
      )[0]

      if (!community) return

      this.$store.commit('selection/community', community)
      this.$store.dispatch('selection/selectCommunity').then(() => {
        this.$router.push({ name: 'admin-general' })
      })
    },
    checkCommunityStatus(status) {
      const accessibleStatus = ['INCOMPLETE', 'TRIALING', 'ACTIVE', 'PAST_DUE']

      return accessibleStatus.includes(status)
    },
    createDebugCommunity() {
      if (!this.checkPermissions()(this.$store.getters['auth/globalPermissionsValue'], 'admin')) return

      this.isLoading = true
      this.$store.dispatch('selection/createDebugCommunity').then(() => {
        this.isLoading = false
      })
    },
    fetchAndUpdateCommunitites() {
      this.$store.dispatch('selection/fetchCommunities').then(response => {
        if (this.$store.state.selection.community) {
          response.forEach(community => {
            if (community.id === this.$store.state.selection.community.id) {
              if (
                !this.checkCommunityStatus(community.community.paymentData.status) ||
                (!this.checkPermissions()(community.permissionsValue, 'accepted') &&
                  !this.checkPermissions()(community.permissionsValue, 'owner'))
              ) {
                this.$store.commit('selection/community', null)
              } else {
                this.$store.commit('selection/community', community)
              }
            }
          })
        }
      })
    },
    closeCommunitiesInteraction() {
      this.isCommunitiesInteractionPopupOpen = false
    }
  },
  computed: {
    selectedCommunity: {
      set(community) {
        if (
          !this.$store.state.selection.community ||
          (this.$store.state.selection.community &&
            this.$store.state.selection.community.community.id !== community.community.id)
        ) {
          this.$store.commit('selection/community', community)
          this.$store.commit('selection/character', null)
          this.$store.commit('selection/role', null)
        }
      },
      get() {
        if (this.$store.state.selection.community === null) {
          return {
            community: {
              id: ''
            }
          }
        }

        return this.$store.state.selection.community
      }
    }
  },
  mounted() {
    this.fetchAndUpdateCommunitites()
  }
}
</script>
