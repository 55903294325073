var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"communities"},[_c('CommunitiesInteractionPopup',{attrs:{"active":_vm.isCommunitiesInteractionPopupOpen},on:{"close":_vm.closeCommunitiesInteraction}}),(_vm.checkPermissions()(_vm.$store.getters['auth/globalPermissionsValue'], 'admin'))?_c('div',[_c('button',{staticClass:"primary-full",class:{ spinner: _vm.isLoading },on:{"click":_vm.createDebugCommunity}},[_vm._v(" Create community (debug) ")])]):_vm._e(),_c('ul',{staticClass:"v-step-all-communities"},[_vm._l((_vm.$store.state.selection.communities),function(community){return _c('li',{key:community.id,on:{"dblclick":function($event){;(!_vm.checkPermissions()(community.permissionsValue, 'accepted') &&
          !_vm.checkPermissions()(community.permissionsValue, 'owner')) ||
        !_vm.checkCommunityStatus(community.community.paymentData.status)
          ? ''
          : _vm.$emit('doubleClickSelect')}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCommunity),expression:"selectedCommunity"}],attrs:{"name":"community-selection","id":'box-' + community.id,"disabled":(!_vm.checkPermissions()(community.permissionsValue, 'accepted') &&
            !_vm.checkPermissions()(community.permissionsValue, 'owner')) ||
            !_vm.checkCommunityStatus(community.community.paymentData.status),"type":"radio"},domProps:{"value":community,"checked":_vm._q(_vm.selectedCommunity,community)},on:{"change":function($event){_vm.selectedCommunity=community}}}),_c('label',{attrs:{"for":'box-' + community.id}},[_c('div',{staticClass:"_box",class:{
            active: _vm.selectedCommunity.id === community.id,
            disabled:
              (!_vm.checkPermissions()(community.permissionsValue, 'accepted') &&
                !_vm.checkPermissions()(community.permissionsValue, 'owner')) ||
              !_vm.checkCommunityStatus(community.community.paymentData.status)
          }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.checkPermissions()(community.permissionsValue, 'accepted') &&
                !_vm.checkPermissions()(community.permissionsValue, 'owner')
            ),expression:"\n              !checkPermissions()(community.permissionsValue, 'accepted') &&\n                !checkPermissions()(community.permissionsValue, 'owner')\n            "}],staticClass:"banner blue"},[_c('h2',{staticClass:"primary"},[_vm._v(_vm._s(_vm.$t('selection.communities.not-accepted')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkCommunityStatus(community.community.paymentData.status)),expression:"!checkCommunityStatus(community.community.paymentData.status)"}],staticClass:"banner blue"},[(
                community.community.paymentData.status !== 'CANCELED' &&
                  _vm.checkPermissions()(community.permissionsValue, 'owner')
              )?_c('h2',{staticClass:"primary"},[_vm._v(" "+_vm._s(_vm.$t('selection.communities.not-paid'))+" ")]):_vm._e(),(
                community.community.paymentData.status === 'CANCELED' &&
                  _vm.checkPermissions()(community.permissionsValue, 'owner')
              )?_c('h2',{staticClass:"primary"},[_vm._v(" "+_vm._s(_vm.$t('selection.communities.canceled'))+" ")]):_vm._e(),(!_vm.checkPermissions()(community.permissionsValue, 'owner'))?_c('h2',{staticClass:"primary"},[_vm._v(" "+_vm._s(_vm.$t('selection.communities.inactive'))+" ")]):_vm._e()]),_c('div',{staticClass:"name"},[_c('h3',{staticClass:"primary"},[_vm._v(_vm._s(community.community.name))])]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":community.community.logo,"alt":"community-image"}})]),(
              _vm.checkPermissions()(community.permissionsValue, 'owner') ||
                _vm.checkPermissions()(community.permissionsValue, 'admin')
            )?_c('div',{staticClass:"edit"},[_c('a',{staticClass:"a-edit",on:{"click":function($event){return _vm.editCommunity(community.community.id)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e()])])])}),_c('li',{staticClass:"ad"},[_c('Adsense',{attrs:{"ins-style":"display:inline-block;width:245px;height:280px","data-ad-client":"ca-pub-7037140739891615","data-ad-slot":"9386554443"}})],1),_c('li',{staticClass:"add",on:{"click":function($event){_vm.isCommunitiesInteractionPopupOpen = true}}},[_vm._m(0)])],2),_c('v-tour',{attrs:{"name":this.$route.name,"steps":_vm.tourSteps,"callbacks":_vm.finishCallbackOnly,"options":_vm.translatedLabels}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_box v-step-join-community"},[_c('div',{staticClass:"icon"},[_c('span',{attrs:{"uk-icon":"icon: plus; ratio: 5;"}})])])
}]

export { render, staticRenderFns }