import { render, staticRenderFns } from "./CommunitiesInteractionPopup.vue?vue&type=template&id=2a432167&scoped=true&"
import script from "./CommunitiesInteractionPopup.vue?vue&type=script&lang=js&"
export * from "./CommunitiesInteractionPopup.vue?vue&type=script&lang=js&"
import style0 from "@/assets/less/components/popup-styles.less?vue&type=style&index=0&id=2a432167&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a432167",
  null
  
)

export default component.exports