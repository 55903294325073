<template>
  <div class="communities-interaction-popup">
    <SinglePopup
      :title="$t('communities-interaction-popup_component.title')"
      name="communities-interaction-"
      class="default"
      :adaptive="true"
      height="400px"
      top="50%"
      :clickToClose="true"
      :active="active"
      @close="close"
    >
      <div class="flexbox" v-if="!isJoining">
        <div class="left">
          <div class="subtitle">
            <h3 v-html="$t('communities-interaction-popup_component.create-a-community-and-invite-your-members')"></h3>
          </div>
          <div class="icon">
            <i class="lc lc-users-add"></i>
          </div>
          <div class="button col-all-12">
            <button :disabled="true" class="primary-full col-all-12" @click="createCommunity">
              {{ $t('communities-interaction-popup_component.create-a-community') }}
            </button>
          </div>
        </div>
        <div class="right">
          <div class="subtitle">
            <h3 v-html="$t('communities-interaction-popup_component.join-a-community-with-a-code')"></h3>
          </div>
          <div class="icons">
            <div class="icon">
              <i class="lc lc-user"></i>
            </div>
            <div class="icon">
              <i class="lc lc-arrow-arc-right"></i>
            </div>
            <div class="icon">
              <i class="lc lc-users"></i>
            </div>
          </div>
          <div class="button">
            <button class="primary col-all-12" @click="isJoining = true">
              {{ $t('communities-interaction-popup_component.join-a-community') }}
            </button>
          </div>
        </div>
      </div>
      <div class="joining-tab" v-if="isJoining">
        <div class="title">
          <h2 class="uk-text-uppercase orange">{{ $t('communities-interaction-popup_component.join-a-community') }}</h2>
        </div>
        <div class="subtitle">
          <h3>{{ $t('communities-interaction-popup_component.enter-an-invite-below-to-join-an-existing-server') }}</h3>
        </div>
        <div class="code-example">
          <p>{{ $t('communities-interaction-popup_component.code-exemple-1') }}</p>
          <p>{{ $t('communities-interaction-popup_component.code-exemple-2') }}</p>
        </div>
        <div class="input">
          <CustomInput
            class="custom-input primary line custom-height col-pc-12"
            inputClasses="col-pc-12"
            v-model="$v.accessCode.$model"
            :model="$v.accessCode"
            :error="fieldsErrors.accessCode"
            type="text"
            :placeholder="$t('communities-interaction-popup_component.access-code_placeholder')"
          ></CustomInput>
        </div>
        <div class="buttons">
          <button class="primary" @click="isJoining = false">{{ $t('actions.return') }}</button>
          <button class="primary-full" @click="joinCommunity" :class="{ spinner: isLoading }">
            {{ $t('actions.join') }}
          </button>
        </div>
      </div>
    </SinglePopup>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import SinglePopup from '@/components/SinglePopup'
import validationMessage from '@/validationMapping'
import { required } from 'vuelidate/lib/validators'

export default {
  components: { SinglePopup },
  data() {
    return {
      isLoading: false,
      isJoining: false,
      fieldsErrors: {},
      accessCode: ''
    }
  },
  props: {
    active: Boolean
  },
  validations: {
    accessCode: {
      required
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    createCommunity() {
      this.$store
        .dispatch('auth/createAuthToken')
        .then(response => {
          this.isLoading = false

          const url = new URL('https://leafcad.com/' + this.$i18n.locale + '/checkout')
          url.searchParams.append('a-token', response.token)

          window.location.replace(url.toString())
        })
        .catch(error => {
          this.isLoading = false
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    joinCommunity() {
      this.isLoading = true

      if (this.$v.$invalid) {
        // this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          accessCode: validationMessage(this.$v.accessCode)
        }

        this.isLoading = false
        return
      }

      this.$store
        .dispatch('selection/joinCommunity', this.accessCode)
        .then(() => {
          this.isLoading = false
          this.isJoining = false
          this.accessCode = ''

          this.close()
        })
        .catch(error => {
          this.isLoading = false

          if (error.graphQLErrors) {
            let errors = []
            error.graphQLErrors.map(({ extensions }) => {
              errors = Object.values(extensions)
            })

            if (errors.includes('ALREADY_JOINED_COMMUNTY')) {
              this.fieldsErrors = {
                accessCode: true
              }
            }
          }

          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }
  },
  watch: {
    active: {
      handler(value) {
        if (value) {
          this.$modal.show('communities-interaction-')
        } else {
          this.$modal.hide('communities-interaction-')
        }
      }
    }
  }
}
</script>
